import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Layout } from '@components';

interface JuryProps extends PageProps {
    data: any;
    pageContext: { locale: string };
}

const Jury: React.FC<JuryProps> = ({ data, pageContext: { locale } }) => {
    const Title = 'Porota';
    const juryData = data.allStrapiJury.nodes;
    // if (lang == 'en') {
    //     Title = 'Jury';
    //     juryData = data.allStrapiJury.nodes.map(({ Name, Photo, localizations }) => {
    //         const Description = localizations.data[0]?.attributes?.Description;
    //         return { Name, Photo, Description };
    //     });
    // }

    return (
        <Layout lang={locale} path="/jury">
            <h1 className="font-bold text-center mt-28 contentBlock">{Title}</h1>
            <div className="">
                {juryData.map(({ Name, Photo, Description }) => {
                    return (
                        <div key={Name}>
                            <div className="flex items-center justify-between w-full h-auto gap-3 pb-10 flex-col-reverse text-center md:justify-center md:flex-row-reverse md:pb-14 md:gap-10 md:text-left">
                                <div className="flex flex-col items-center md:items-start mx-2 md:mx-0">
                                    <h3>{Name}</h3>
                                    <p className="text-sm max-w-80 md:max-w-[490px]">
                                        {Description}
                                    </p>
                                </div>
                                <div>
                                    <div>
                                        {Photo?.localFile && (
                                            <GatsbyImage
                                                className="left-0 object-center w-32 h-32 rounded-full md:h-40 md:w-40"
                                                image={
                                                    Photo.localFile.childImageSharp.gatsbyImageData
                                                }
                                                alt={Name}
                                                objectFit={'cover'}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
};

export default Jury;

export const query = graphql`
    query JuryQuery($locale: String!) {
        allStrapiJury(filter: { locale: { eq: $locale } }) {
            nodes {
                Name
                Photo {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                Description
            }
        }
    }
`;
